import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Список ссылок в JSON формате
const profileLinks = [
    { id: 1, title: 'Мои цели', href: '/goals' },
    { id: 2, title: 'Прогресс', href: '/progress' },
    { id: 3, title: 'Главная', href: '/' },
    { id: 4, title: 'Политика конфиденциальности', href: '/privacy-policy' },
    { id: 5, title: 'Настройки', href: '/settings' }
];

const Profile = () => {
    const [userData, setUserData] = useState(null); // Данные пользователя

    const getAuthToken = () => localStorage.getItem('access_token');

    const axiosInstance = axios.create({
        baseURL: 'https://tnlbotapi.simplr.ru',
        headers: {
            Authorization: `Bearer ${getAuthToken()}`,
        },
    });

    // Получаем данные пользователя с API
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axiosInstance.get('/api/auth/v1/get_user');
                setUserData(response.data.user);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    return (
        <div className="profile-container">
            <h1 className="profile-header">Профиль</h1>
            {userData ? (
                <div className="profile-info">
                    <img
                        src={userData.avatar || 'https://via.placeholder.com/150'} // Плейсхолдер для аватара
                        alt="User Avatar"
                        className="profile-avatar"
                    />
                    <p><strong>Имя:</strong> {userData.name || 'Не указано'}</p>
                    <p><strong>Email:</strong> {userData.email}</p>
                </div>
            ) : (
                <p>Загрузка данных пользователя...</p>
            )}

            <nav className="profile-links">
                <ul>
                    {profileLinks.map(link => (
                        <li key={link.id}>
                            <a href={link.href} className="profile-link">
                                {link.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default Profile;
