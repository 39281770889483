import React from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Chat from "./components/Chat";
import SobrietyCounter from "./components/SobrietyCounter";
import NavigationBar from "./components/NavigationBar";
import Journal from "./pages/Journal";
import Auth from "./pages/Auth";

const App = () => {
    const location = useLocation();
    const noFooterRoutes = ['/auth', '/onboarding', '/onboarding/form', '/ban'];
    const showFooter = !noFooterRoutes.includes(location.pathname);

    return (
        <div className='app-wrapper'>
            <div className="container">
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/chat" element={<Chat/>}/>
                    <Route path="/counter" element={<SobrietyCounter/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/journal" element={<Journal/>}/>
                    <Route path="/auth" element={<Auth/>}/>
                </Routes>
                {showFooter && <NavigationBar/>}
            </div>
        </div>

    );
};

const styles = {
    appContainer: {
        paddingBottom: '60px', // Отступ для навигационного бара внизу
    },
};

export default App;