import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Journal = () => {
    const [entries, setEntries] = useState([]);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [mood, setMood] = useState(3); // Значение по умолчанию — normal
    const [isAdding, setIsAdding] = useState(false);

    const moods = [
        { id: 1, label: 'Terrible' },
        { id: 2, label: 'Rough' },
        { id: 3, label: 'Normal' },
        { id: 4, label: 'Good' },
        { id: 5, label: 'Great' },
        { id: 6, label: 'Excellent' }
    ];

    const fetchJournalEntries = async () => {
        try {
            const response = await axios.get('https://tnlbotapi.simplr.ru/api/v1/journal', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            setEntries(response.data);
        } catch (error) {
            console.error('Ошибка при получении записей:', error);
        }
    };

    useEffect(() => {
        fetchJournalEntries();
    }, []);

    const handleAddEntry = async () => {
        if (!title.trim() || !content.trim()) {
            alert("Заполните заголовок и текст.");
            return;
        }

        try {
            await axios.post('https://tnlbotapi.simplr.ru/api/v1/journal', {
                title,
                content,
                mood  // Передаем ID настроения
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                }
            });

            // Обновление списка записей
            fetchJournalEntries();
            setTitle('');
            setContent('');
            setIsAdding(false);
        } catch (error) {
            console.error('Ошибка при добавлении записи:', error);
        }
    };

    return (
        <div className="journal-container">
            <h1 className="journal-header">Личный дневник</h1>

            {isAdding ? (
                <div className="entry-form">
                    <input
                        type="text"
                        placeholder="Заголовок"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="entry-input"
                    />
                    <textarea
                        placeholder="Текст записи"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="entry-textarea"
                    />
                    <select value={mood} onChange={(e) => setMood(parseInt(e.target.value))}>
                        {moods.map(m => (
                            <option key={m.id} value={m.id}>{m.label}</option>
                        ))}
                    </select>
                    <button onClick={handleAddEntry} className="add-entry-button">Создать запись</button>
                    <button onClick={() => setIsAdding(false)} className="cancel-entry-button">Отменить</button>
                </div>
            ) : (
                <button onClick={() => setIsAdding(true)} className="add-entry-button">
                    Добавить запись
                </button>
            )}

            <div className="entries-list">
                {entries.length > 0 ? (
                    entries.map((entry) => (
                        <div key={entry.id} className="entry-card">
                            <h2 className="entry-title">{entry.title}</h2>
                            <p className="entry-date">{entry.date}</p>
                            <p className="entry-content">{entry.content}</p>
                            <p className="entry-mood">Mood: {entry.mood}</p> {/* Отображаем настроение */}
                        </div>
                    ))
                ) : (
                    <p className="no-entries">Записей пока нет</p>
                )}
            </div>
        </div>
    );
};

export default Journal;
