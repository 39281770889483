import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Auth = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    // Регистрация пользователя
    const handleRegister = async () => {
        try {
            const response = await axios.post('https://tnlbotapi.simplr.ru/api/auth/v1/create_user', { email, password });
            if (response.data.status) {
                setMessage('Регистрация успешна! Вы можете войти.');
                setIsLogin(true);
            } else {
                setMessage(response.data.user === 'already_exist' ? 'Email уже зарегистрирован' : 'Пароль слишком короткий');
            }
        } catch (error) {
            setMessage('Ошибка при регистрации.');
            console.error(error);
        }
    };

    // Авторизация пользователя
    const handleLogin = async () => {
        try {
            const response = await axios.post('https://tnlbotapi.simplr.ru/api/auth/v1/login', { email, password });
            const { access_token, user_id, thread } = response.data;

            // Сохраняем токен в localStorage
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('user_id', user_id);
            localStorage.setItem('thread_id', thread);

            setMessage('Авторизация успешна!');
            navigate('/profile'); // Перенаправление на страницу профиля
        } catch (error) {
            setMessage('Неправильный email или пароль');
            console.error(error);
        }
    };

    // Переключение между регистрацией и авторизацией
    const toggleAuthMode = () => {
        setIsLogin(!isLogin);
        setMessage('');
    };

    // Обработчик отправки формы
    const handleSubmit = (e) => {
        e.preventDefault();
        if (isLogin) {
            handleLogin();
        } else {
            handleRegister();
        }
    };

    return (
        <div className="auth-container">
            <h1>{isLogin ? 'Авторизация' : 'Регистрация'}</h1>
            <form onSubmit={handleSubmit} className="auth-form">
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="auth-input"
                />
                <input
                    type="password"
                    placeholder="Пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="auth-input"
                />
                <button type="submit" className="auth-button">
                    {isLogin ? 'Войти' : 'Зарегистрироваться'}
                </button>
            </form>
            {message && <p className="auth-message">{message}</p>}
            <button onClick={toggleAuthMode} className="toggle-auth-button">
                {isLogin ? 'Нет аккаунта? Зарегистрироваться' : 'Уже есть аккаунт? Войти'}
            </button>
        </div>
    );
};

export default Auth;