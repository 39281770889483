import React, {useState, useEffect} from 'react';
import axios from 'axios';

const SobrietyCounter = () => {
    const [elapsedTime, setElapsedTime] = useState({});
    const [sobrietyStartDate, setSobrietyStartDate] = useState(null); // Дата начала трезвости
    const [goals, setGoals] = useState([]); // Основные цели
    const [customGoals, setCustomGoals] = useState([]); // Кастомные цели
    const [selectedCustomGoal, setSelectedCustomGoal] = useState(''); // Для выбора цели из селекта
    const [userCustomGoals, setUserCustomGoals] = useState([]); // Цели, выбранные пользователем

    const getAuthToken = () => localStorage.getItem('access_token');

    const axiosInstance = axios.create({
        baseURL: 'https://tnlbotapi.simplr.ru',
        headers: {
            Authorization: `Bearer ${getAuthToken()}`,
        },
    });

    // Загружаем основные и кастомные цели из API
    useEffect(() => {
        const fetchGoals = async () => {
            try {
                const [goalsResponse, customGoalsResponse] = await Promise.all([
                    axiosInstance.get('/api/v1/goals'),
                    axiosInstance.get('/api/v1/custom_goals'),
                ]);
                setGoals(goalsResponse.data);
                setCustomGoals(customGoalsResponse.data);
            } catch (error) {
                console.error('Error fetching goals:', error);
            }
        };

        fetchGoals();
    }, []);

    // Получение даты трезвости с бэкенда и запуск таймера
    useEffect(() => {
        const fetchSobrietyStartDate = async () => {
            try {
                const response = await axiosInstance.get('/api/v1/user_progress');
                const startDate = new Date(response.data.sobriety_start_date); // Дата в UTC
                setSobrietyStartDate(startDate);

                const timer = setInterval(() => {
                    const now = new Date(); // Текущее время в UTC
                    const diff = now.getTime() - startDate.getTime(); // Разница во времени в миллисекундах

                    const seconds = Math.floor(diff / 1000) % 60;
                    const minutes = Math.floor(diff / (1000 * 60)) % 60;
                    const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
                    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
                    const weeks = Math.floor(days / 7);
                    const months = Math.floor(days / 30);
                    const years = Math.floor(days / 365);

                    setElapsedTime({seconds, minutes, hours, days, weeks, months, years});
                }, 1000);

                return () => clearInterval(timer);
            } catch (error) {
                console.error('Error fetching sobriety start date:', error);
            }
        };

        fetchSobrietyStartDate();
    }, []);

    // Добавление пользовательской цели
    const handleAddCustomGoal = () => {
        if (!selectedCustomGoal) return;

        const goal = customGoals.find(g => g.id === parseInt(selectedCustomGoal, 10));
        if (goal && !userCustomGoals.find(g => g.id === goal.id)) {
            const updatedGoals = [...userCustomGoals, {...goal, completed: false}];
            setUserCustomGoals(updatedGoals);
            localStorage.setItem('userCustomGoals', JSON.stringify(updatedGoals));
            setSelectedCustomGoal(''); // Сбрасываем выбранную цель
        }
    };

    // Отметка пользовательской цели как выполненной
    const handleCompleteCustomGoal = (goalId) => {
        const updatedGoals = userCustomGoals.map(goal =>
            goal.id === goalId ? {...goal, completed: !goal.completed} : goal
        );
        setUserCustomGoals(updatedGoals);
        localStorage.setItem('userCustomGoals', JSON.stringify(updatedGoals));
    };

    const handleResetCounter = () => {
        const newStartDate = new Date();
        const utcStartDate = new Date(newStartDate.toISOString()); // Преобразуем дату в UTC
        axiosInstance.post('/api/v1/reset_counter', {new_start_date: utcStartDate.toISOString()})
            .then(() => {
                setSobrietyStartDate(utcStartDate);
                setElapsedTime({});
            })
            .catch((error) => {
                console.error('Error resetting counter:', error);
            });
    };


    // Фильтруем доступные кастомные цели, чтобы они исчезали из селекта после добавления
    const availableCustomGoals = customGoals.filter(
        (goal) => !userCustomGoals.some((userGoal) => userGoal.id === goal.id)
    );

    return (
        <div className="sobriety-counter-container">
            <h2 className="sobriety-title">Трезвость: свобода в течение</h2>
            <div className="time-container">
                {/* Таймер */}
                <div className="time-box">
                    <span className="time-value">{elapsedTime.years ?? 0}</span>
                    <span className="time-label">лет</span>
                </div>
                <div className="time-box">
                    <span className="time-value">{elapsedTime.months % 12 ?? 0}</span>
                    <span className="time-label">месяцев</span>
                </div>
                <div className="time-box">
                    <span className="time-value">{elapsedTime.weeks % 4 ?? 0}</span>
                    <span className="time-label">недель</span>
                </div>
                <div className="time-box">
                    <span className="time-value">{elapsedTime.days % 7 ?? 0}</span>
                    <span className="time-label">дней</span>
                </div>
                <div className="time-box">
                    <span className="time-value">{elapsedTime.hours ?? 0}</span>
                    <span className="time-label">часов</span>
                </div>
                <div className="time-box">
                    <span className="time-value">{elapsedTime.minutes ?? 0}</span>
                    <span className="time-label">минут</span>
                </div>
                <div className="time-box">
                    <span className="time-value">{elapsedTime.seconds ?? 0}</span>
                    <span className="time-label">секунд</span>
                </div>
            </div>

            <button onClick={handleResetCounter} className="reset-button">
                Сбросить счётчик
            </button>

            <h3 className="goals-title">Ваши цели</h3>
            <div className="goals-container">
                {goals.map((goal) => (
                    <div key={goal.id}
                         className={`goal-box ${elapsedTime.days >= goal.days ? 'goal-active' : 'goal-inactive'}`}>
                        <img src={goal.goalImage} alt={goal.title} className="goal-image"/>
                        {goal.title}
                    </div>
                ))}
            </div>

            <h3 className="goals-title">Личные цели</h3>
            <div className="custom-goals-container">
                {userCustomGoals.map((goal) => (
                    <div key={goal.id} className={`goal-box ${goal.completed ? 'goal-active' : 'goal-inactive'}`}
                         onClick={() => handleCompleteCustomGoal(goal.id)}>
                        <img src={goal.goalImage} alt={goal.title} className="goal-image"/>
                        {goal.title}
                    </div>
                ))}
            </div>

            <select value={selectedCustomGoal} onChange={(e) => setSelectedCustomGoal(e.target.value)}
                    className="goal-select">
                <option value="">Выберите цель</option>
                {availableCustomGoals.map((goal) => (
                    <option key={goal.id} value={goal.id}>
                        {goal.title}
                    </option>
                ))}
            </select>
            <button onClick={handleAddCustomGoal} className="add-goal-button">Добавить цель</button>
        </div>
    );
};

export default SobrietyCounter;
