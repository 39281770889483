import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const API_URL = 'https://tnlbotapi.simplr.ru/api/v1/add_message_to_thread';
const WS_URL = 'wss://tnlbotapi.simplr.ru/ws';

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const websocketRef = useRef(null);
    const currentMessageWSRef = useRef('');

    // WebSocket connection
    const connectWebSocket = () => {
        const threadId = localStorage.getItem('thread_id');
        const assistantId = 'asst_t89DQqlMMBTOFsyMe3g9vLvf';

        websocketRef.current = new WebSocket(`${WS_URL}?thread_id=${threadId}&assistant_id=${assistantId}`);

        websocketRef.current.onopen = () => {
            console.log('WebSocket connection established');
            currentMessageWSRef.current = '';
        };

        websocketRef.current.onmessage = (event) => {
            currentMessageWSRef.current += event.data;

            setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                const lastMessage = newMessages[newMessages.length - 1];

                if (lastMessage && lastMessage.from === 'bot') {
                    newMessages[newMessages.length - 1] = {
                        ...lastMessage,
                        content: currentMessageWSRef.current,
                    };
                } else {
                    newMessages.push({
                        from: 'bot',
                        content: currentMessageWSRef.current,
                        time: new Date().toISOString(),
                    });
                }

                return newMessages;
            });
        };

        websocketRef.current.onclose = () => {
            console.log('WebSocket connection closed');
        };
    };

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (websocketRef.current) websocketRef.current.close();
        };
    }, []);

    const sendMessage = async () => {
        if (input.trim()) {
            const userMessage = {
                from: 'user',
                content: input,
                time: new Date().toISOString(),
            };
            setMessages((prevMessages) => [...prevMessages, userMessage]);

            if (websocketRef.current) websocketRef.current.close();

            try {
                const response = await axios.post(
                    `${API_URL}?thread_id=thread_ESXBBgtbjXuxXYh1cNTWMt0H`,
                    { content: input },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                if (response.data.status) {
                    connectWebSocket();
                }
            } catch (error) {
                console.error('Error sending message:', error);
            }

            setInput('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') sendMessage();
    };

    return (
        <div className="chat-container">
            <h2 className="chat-header">Chat with Assistant</h2>
            <div className="chat-messages-container">
                {messages.map((msg, index) => (
                    <div
                        key={index}
                        className={`chat-message ${msg.from === 'user' ? 'chat-user-message' : 'chat-bot-message'}`}
                    >
                        <ReactMarkdown
                            children={msg.content}
                            remarkPlugins={[remarkGfm]}
                            components={{
                                h1: ({ node, ...props }) => <h1 className="chat-heading" {...props} />,
                                h2: ({ node, ...props }) => <h2 className="chat-heading" {...props} />,
                                p: ({ node, ...props }) => <p className="chat-paragraph" {...props} />,
                                ul: ({ node, ...props }) => <ul className="chat-list" {...props} />,
                                li: ({ node, ...props }) => <li className="chat-list-item" {...props} />,
                                strong: ({ node, ...props }) => <strong className="chat-strong" {...props} />,
                            }}
                        />
                    </div>
                ))}
            </div>
            <div className="chat-input-container">
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message here..."
                    className="chat-input"
                />
                <button onClick={sendMessage} className="chat-send-button">➤</button>
            </div>
        </div>
    );
};

export default Chat;