import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Home = () => {
    const [progressDays, setProgressDays] = useState(0);
    const [goals, setGoals] = useState([]); // Основные цели с бэка
    const [customGoals, setCustomGoals] = useState([]); // Личные цели с бэка
    const [dailyTip, setDailyTip] = useState("");

    // Функция для получения токена из localStorage
    const getAuthToken = () => {
        return localStorage.getItem('access_token');
    };

    // Настройка Axios для передачи токена в заголовках
    const axiosInstance = axios.create({
        baseURL: 'https://tnlbotapi.simplr.ru',
        headers: {
            Authorization: `Bearer ${getAuthToken()}`
        }
    });

    // Приветствие и прогресс
    useEffect(() => {
        const fetchProgress = async () => {
            try {
                const response = await axiosInstance.get('/api/v1/user_progress');
                const { progress_days } = response.data;
                setProgressDays(progress_days);
            } catch (error) {
                console.error('Ошибка получения прогресса пользователя:', error);
            }
        };
        fetchProgress();
    }, []);

    // Получение целей с бэка
    useEffect(() => {
        const fetchGoals = async () => {
            try {
                const [goalsResponse, customGoalsResponse] = await Promise.all([
                    axiosInstance.get('/api/v1/goals'),
                    axiosInstance.get('/api/v1/custom_goals')
                ]);
                setGoals(goalsResponse.data);
                setCustomGoals(customGoalsResponse.data);
            } catch (error) {
                console.error('Ошибка получения целей:', error);
            }
        };
        fetchGoals();
    }, []);

    // Полезные советы (обновляется раз в день)
    useEffect(() => {
        const fetchDailyTip = async () => {
            try {
                const response = await axiosInstance.get('/api/v1/get_daily_tip');
                const { daily_tip } = response.data;
                setDailyTip(daily_tip.daily_tip);
            } catch (error) {
                console.error('Ошибка получения ежедневного совета:', error);
            }
        };
        fetchDailyTip();
    }, []);

    return (
        <div className="home-container">
            <h1 className="home-header">Добро пожаловать!</h1>

            {/* Приветствие и прогресс */}
            <div className="progress-card">
                <h2>Ваш прогресс</h2>
                <p>Вы трезвы уже <strong>{progressDays}</strong> дней!</p>
            </div>

            {/* Полезный совет */}
            <div className="tip-card">
                <h2>Полезный совет дня</h2>
                <p>{dailyTip}</p>
            </div>

            {/* Карточка с текущими целями */}
            <div className="goals-card">
                <h2>Ваши цели</h2>
                <ul>
                    {goals.map((goal) => (
                        <li
                            key={goal.id}
                            className={`goal ${progressDays >= goal.days ? 'goal-active' : 'goal-inactive'}`}
                        >
                            <img src={goal.goalImage} alt={goal.title} className="goal-image" />
                            {goal.title} - {goal.days} дней
                        </li>
                    ))}
                </ul>
            </div>

            {/* Карточка с личными целями */}
            <div className="goals-card">
                <h2>Личные цели</h2>
                <ul>
                    {customGoals.map((goal) => (
                        <li key={goal.id} className="goal">
                            <img src={goal.goalImage} alt={goal.title} className="goal-image" />
                            {goal.title}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Home;
