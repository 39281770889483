import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const Root = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Проверяем наличие токена и thread_id в localStorage
        const token = localStorage.getItem('access_token');
        const threadId = localStorage.getItem('thread_id');

        // Если их нет, перенаправляем на страницу авторизации
        if (!token || !threadId) {
            navigate('/auth');
        }
    }, [navigate]);

    return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Root />
    </BrowserRouter>
);
