import React from 'react';
import { NavLink } from 'react-router-dom';


const NavigationBar = () => {
    return (
        <div className="navbar">
            <NavLink to="/" className="navbar-item" activeclassname="active">
                <span role="img" aria-label="home">🏠</span>
                <p>Главная</p>
            </NavLink>
            <NavLink to="/chat" className="navbar-item" activeclassname="active">
                <span role="img" aria-label="chat">💬</span>
                <p>Чат</p>
            </NavLink>
            <NavLink to="/counter" className="navbar-item" activeclassname="active">
                <span role="img" aria-label="counter">📅</span>
                <p>Мои цели</p>
            </NavLink>
            <NavLink to="/journal" className="navbar-item" activeclassname="active">
                <span role="img" aria-label="counter">📎</span>
                <p>Журнал</p>
            </NavLink>
            <NavLink to="/profile" className="navbar-item" activeclassname="active">
                <span role="img" aria-label="profile">👤</span>
                <p>Профиль</p>
            </NavLink>
        </div>
    );
};

export default NavigationBar;